<template lang="pug">
	.card.card-custom.gutter-b
		.card-header
			.card-title
				.card-label Report Fetch Jobs
			.card-toolbar
		.card-body
			loading(:active.sync="busy", :is-full-page="false")
			form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
				a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
					span Filters
					i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
				b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
					.row.filters-form-row
						.form-row-main.col-auto.mr-auto(style="flex:1;")
							.form-group
								label Date From
								input.form-control(type="text", v-model="filters.date_from")
							.form-group
								label Date To
								input.form-control(type="text", v-model="filters.date_to")
							.form-group
								label Status
								select.form-control(v-model="filters.status")
									option(:value="null") - All -
									option(v-for="o in statusOptions", :value="o.v") {{ o.t }}
							.form-group
								label Report Type
								select.form-control(v-model="filters.report_type")
									option(:value="null") - All -
									option(v-for="o in reportTypeOptions", :value="o.v") {{ o.t }}
							.form-group
								label Network
								select.form-control(v-model="filters.network_id", style="width: 120px;")
									option(:value="null") - All -
									option(v-for="n in networkOptions", :value="n.id") {{ n.name }}
							.form-group
								label MMP Account
								select.form-control(v-model="filters.mmp_account_id", style="width: 120px;")
									option(:value="null") - All -
									option(v-for="o in mmpAccountOptions", :value="o.v") {{ o.t }}
							.form-group(style="width: 230px;")
								label Asset
								v-select.select-asset(v-model="selectedAsset", :options="assetOptions", label="name")
									template(v-slot:option="option")
										span.select-asset-option
											span.select-asset-icon
												img(:src="option.icon || ''")
											span.select-asset-text
												span.select-asset-name {{ option.name }}
												span.select-asset-package {{ option.package_id }}
							.form-group.no-label
								button.btn.btn-primary(type="submit") Go

			p.clearfix &nbsp;

			p Found <strong>{{ paginate.total }}</strong> records

			div.table-container
				table.table.table-bordered.table-compact
					thead
						tr
							th ID
							th Report Type
							th MMP Account
							th Asset
							th Dates
							th Num Cords
							th Status
							th Error Message
							th Created At
							th Actions
					tbody
						tr(v-if="records.length===0")
							td(colspan="8") No matching records were found
						tr(v-for="r in records", :key="r.id")
							td {{ r.id }}
							td.col-report-type {{ r.report_type }}
							td
								entity(type="mmp_account", :id="r.mmp_account.id", :name="r.mmp_account.name")
							td [{{  r.asset.id }}] {{ r.asset.name }}
								div
									small.text-muted {{ r.asset.package_id }}
							td {{ r.date_from }}
								span(v-if="r.date_to !== r.date_from") - {{ r.date_to }}
							td {{ r.num_records }}
							td {{ r.status }}
							td
								div.col-error_message(v-if="r.error_message") {{ r.error_message }}
							td {{ r.created_at }}
							td.actions
								button.btn.btn-action.btn-outline-primary(type="button", @click='resetRecord(r.id)')
									i.la.la-refresh
								button.btn.btn-action.btn-outline-danger(type="button", @click='deleteRecord(r.id)')
									i.la.la-trash

			paginate(:paginator="paginate", @update-page-size="loadData")

</template>
<style lang="scss">
.table-container {
	overflow: auto;
}
.col-error_message {
	background: #f0f0f0;
	display: block;
	max-width: 200px;
	overflow: auto;
	padding: 5px;
	border-radius: 5px;
}
</style>
<script>
import Vue from 'vue';
import moment from 'moment';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';

async function loadRoute(vm, to, from, next){
	let resp = await Vue.$api.get('/raw-data/getAppsflyerFields');
	let fields = resp.fields;

	let assetResult = await Vue.$api.get('/assets/getList', {page: 1, limit: 1000});
	let networkOptions = await Vue.$dwData.network.getNetworks();
	let mmpAccountOptions = await Vue.$dwData.mmpAccount.getOptions();
	return vm => {
		vm.fields = fields;
		vm.assetOptions = assetResult.records;
		vm.networkOptions = networkOptions;
		vm.mmpAccountOptions = mmpAccountOptions;
		vm.loadData();
		return vm;
	};
}

export default {
	name: 'RawDataReport',
	async beforeRouteEnter(to, from, next){
		let bind = await loadRoute({}, to, from, next);
		next(bind);
	},
	async beforeRouteUpdate(to, from, next){
		await loadRoute(this, to, from, next);
		next();
	},
	data(){
		return {
			busy: false,
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			filters: {
				network_id: null,
				mmp_account_id: null,
				date_from: moment().format('YYYY-MM-DD'),
				date_to: moment().format('YYYY-MM-DD'),
				report_type: null,
				status: null,
			},
			networkOptions: [],
			mmpAccountOptions: [],
			assetOptions: [],
			selectedAsset: null,
			statusOptions: Vue.$dwData.reportFetchJob.statusOptions,
			reportTypeOptions: Vue.$dwData.general.reportTypeOptions,
			records: [],
			filtersExpanded: false,
		};
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [
			{ title: 'Monitor' },
			{ title: 'Report Fetch Jobs' }
		]);
	},
	methods: {
		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let params = {
				status: this.filters.status,
				date_from: this.filters.date_from,
				date_to: this.filters.date_to,
				page: this.paginate.page,
				limit: this.paginate.limit,
				network_id: this.filters.network_id,
				mmp_account_id: this.filters.mmp_account_id,
				asset_id: this.selectedAsset ? this.selectedAsset.id : 0,
				report_type: this.filters.report_type
			};
			try {
				let resp = await this.$api.get('/report-fetch-jobs/getList', {params});
				this.records = resp.records;
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e){
				console.error(e);
			}
			this.busy = false;
		},
		async resetRecord(entityId) {
			if (this.busy){
				return;
			}
			this.busy = true;
			try {
				let data = {
					id: entityId
				};
				let resp = await this.$api.post('/report-fetch-jobs/resetRecord', data);
				this.busy = false;
				this.loadData();
			} catch (e){
				console.error(e);
				this.busy = false;
			}

		},

		async deleteRecord(entityId) {
			if (this.busy) {
				return;
			}
			if (confirm('Are you sure?')) {
				this.busy = true;
				try {
					let resp = await Vue.$api.delete('/report-fetch-jobs/' + entityId);
					this.busy = false;
					this.loadData();
				} catch (e) {
					console.error(e);
					this.busy = false;
				}
			}
		}
	},
}
</script>
